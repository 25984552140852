.container-box{
	position: relative;
	width: 100%;
	background-color: #fff;
	box-shadow: 0.8em 0.8em 0 0 var(--primary-color);
	border: 2px solid var(--primary-color);
	margin-top: 1.5em;
	padding: 1.5em;
	box-sizing: border-box;
}

.container-box-b{
	position: relative;
	width: 100%;
	background-color: #fff;
	box-shadow: 0.8em 0.8em 0 0 #000;
	border: 2px solid #000;
	margin-top: 1.5em;
	padding: 1.5em;
	box-sizing: border-box;
}
.title-underline {
    padding: 0px 0 0px;
    position: relative;
    z-index: 5;
}

.title-underline::before {
    content: "";
    position: absolute;
    /* top:30px; */
    /* left: 45%; */
    bottom: 10%;
    width: 104%;
    height: 60%;
    transform: skew(-12deg) translateX(-2%);
    background: var(--primary-color);
    transition: opacity 1s ease,width 0.7s cubic-bezier(.75,0,.175,1);
    z-index: -1;
}

.text-underline {
    padding: 0px 0 0px;
    position: relative;
    z-index: 5;
    font-weight: inherit;
}

.text-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10%;
    width: 104%;
    height: 60%;
    transform: skew(-12deg) translateX(-2%);
    background: var(--primary-color);
    z-index: -1;
}
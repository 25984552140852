ul {
    margin: 150px auto 0;
    padding: 0;
    list-style: none;
    display: table;
    width: 600px;
    text-align: center;
}

.tab {
    display: table-cell;
    position: relative;
    padding: 15px 0;
}

.tab-selected:before {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
}

.tab span {
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;

    display: inline-block;
    padding: 15px 20px;
    position: relative;
}

.tab span:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: black;
    transition: width 0.7s ease 0s, left 0.7s ease 0s;
    width: 0;
}

.tab span:hover:after {
    width: 100%;
    left: 0;
}

@media screen and (max-height: 300px) {
    ul {
        margin-top: 40px;
    }
}
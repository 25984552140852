.btn-slide {
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    /* Add vendor-specific property */
    padding-right: 20px;
    border-radius: 9999px;
    overflow: hidden;
    cursor: pointer;
}

.btn-slide:before {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    border-radius: 9999px;
    height: 3rem;
    position: absolute;
    background: var(--primary-color);
    transition: opacity 1s ease, width 0.7s cubic-bezier(.75, 0, .175, 1);
    width: 0;
    z-index: -1;
}

.btn-slide:focus, .btn-slide:hover {
    outline: 0
}

.btn-slide:focus:before, .btn-slide:hover:before, .btn-slide:hover:after {
    width: 100%
}

.btn-slide div {
    transition: all 1s;
}

.tilt-shaking {
    animation: tilt-shaking 0.2s infinite;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

  .loading-end{
    top: 0;
    transition: all .25s;
    overflow: hidden;
    height: 0;
  }

  .loading {
    height: 100vh;
    width: 100vw;
  }

  .loader{
    height: 100vh;
    width: 100vw;
    background-color:   var(--primary-color);
    /* filter: contrast(200%); */
  }

  ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
  }

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loaderlogo {
    width: 75px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(farthest-side,#fff 94%,#ff00);
    background:
      var(--_g) 0    0,
      var(--_g) 100% 0,
      var(--_g) 100% 100%,
      var(--_g) 0    100%;
    background-size: 40% 40%;
    animation: l38 .5s infinite; 
  }
  @keyframes l38 {
    100% {background-position: 100% 0,100% 100%,0 100%,0 0}
  }
.bg-img {
    background-color: var(--primary-color);
    width: 24rem;
    height: 24rem;
    border-radius: 9999px;
    content: "";
}

.bg-img-1 {
    transform: translateX(50%) translateY(-40%);
    opacity: 1;
}

.bg-img-2 {
    transform: translateX(-20%) translateY(-50%);
    opacity: 0.8;
}

.bg-img-3 {
    transform: translateX(90%) translateY(-120%);
    opacity: 0.6;
}

.bg-img-4 {
    transform: translateX(10%) translateY(-140%);
    opacity: 0.4;
}

.home-block-list-form {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.home-block-list-form-bg {
    position: absolute;
    display: flex;
    background-color: #fff;
    border-bottom: 10px solid #000;
    border-left: 10px solid #000;
    width: 100%;
    height: 100%;
    /* transform: skew(-5deg); */
}

.home-block-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    margin: 0.25rem;
    transform: translateX(10px) translateY(0px);
}

.form-list {
    transform: translateX(10px) translateY(0px);
    /* margin-top: 0.75rem;
    margin-bottom: 0.75rem; */
}

.form-list-title {
    font-size: 1rem;
    font-weight: bolder;
    color: #000;
    margin-left: 0.5rem;
}

.form-list-name {
    position: relative;
}

.form-list-name-active:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    background: black;
    transition: width 1s ease 0s;
    width: 0;
}

.form-list-name-active:hover:after {
    width: 100%;
    left: 0;
}

.form-list-status {
    background-color: var(--primary-color);
    border-radius: 9999px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: #fff;
}

@media (min-width: 768px) {
    .home-block-list-form {
        padding: 1rem;
        margin: 1rem;
        padding-bottom: 4rem;
        width: 97%;
    }

    .home-block-list-form-bg {
        position: absolute;
        display: flex;
        background-color: #fff;
        border-bottom: 10px solid #000;
        border-left: 10px solid #000;
        width: 150%;
        height: 100%;
        right: -50%;
        transform: skew(-10deg);
    }

    .home-block-title {
        font-size: 3rem;
        font-weight: 700;
        color: #000;
        /* margin: 1rem; */
        transform: translateX(3vw) translateY(20px);
    }

    .form-list {
        transform: translateX(3vw) translateY(20px);
        /* margin-top: 0.75rem; */
        /* margin-bottom: 0.75rem; */
    }

    .form-list-title {
        font-size: 1rem;
        font-weight: bolder;
        color: #000;
        /* margin: 1rem; */
    }

    .form-list-name {
        position: relative;
    }

    .form-list-name-active:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        transform: translateY(5px);
        position: absolute;
        background: black;
        transition: width 1s ease 0s;
        width: 0;
    }

    .form-list-name-active:hover:after {
        width: 100%;
        left: 0;
    }

    .form-list-status {
        background-color: var(--primary-color);
        border-radius: 9999px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.7rem;
        font-weight: 500;
        color: #fff;
    }
}
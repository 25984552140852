.degrade-rect{
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color) 100%);
    margin-left: 50px;
    margin-top: 4rem;
    width: 24rem;
    height: calc(100vh - 40rem);
    display: block;
    content: "";
}

.triangle{
    width: 0;
    height: 0;
    border-left: calc(90vh - 50rem) solid transparent;
    border-right: calc(90vh - 50rem) solid transparent;
    border-bottom: calc(90vh - 50rem) solid #000;
    margin-left: 100px;
    margin-top: 4rem;
    transform: rotate(-37deg); /* Inclinaison du triangle */
}
/* Fields */

.hs-form-field {
    margin-bottom: 1.4rem;
}

/* Labels */

form label {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.35rem;
}

/* Help text */

form legend {
    font-size: 0.875rem;
}

/* Inputs */

form input[type=text],
form input[type=email],
form input[type=password],
form input[type=tel],
form input[type=number],
form input[type=file],
form input[type=date],
form select,
form textarea {
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.7rem;
    width: 100%;
    border-color: rgb(209, 213, 219);
    border-width: 1px;
}

form fieldset {
    max-width: 100% !important;
}

/* Inputs - checkbox/radio */

form .inputs-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

form .inputs-list>li {
    display: block;
    margin: 0.7rem 0;
}

form .inputs-list input,
form .inputs-list span {
    vertical-align: middle;
}

form input[type=checkbox],
form input[type=radio] {
    cursor: pointer;
    margin-right: 0.35rem;
}

/* Inputs - date picker */

.hs-dateinput {
    position: relative;
}

.hs-dateinput:before {
    content: '\01F4C5';
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.fn-date-picker .pika-table thead th {
    color: #FFF;
}

.fn-date-picker td.is-selected .pika-button {
    border-radius: 0;
    box-shadow: none;
}

.fn-date-picker td .pika-button:hover,
.fn-date-picker td .pika-button:focus {
    border-radius: 0 !important;
    color: #FFF;
}

/* Inputs - file picker */

form input[type=file] {
    background-color: transparent;
    border: initial;
    padding: initial;
}

/* Headings and text */

form .hs-richtext,
form .hs-richtext p {
    font-size: 0.875rem;
    margin: 0 0 1.4rem;
}

form .hs-richtext p {
    font-size: 0.875rem;
    margin: 0 0 1.4rem;
    color: #999999;
    font-style: italic;
}

form .hs-richtext img {
    max-width: 100% !important;
}

/* GDPR */

.legal-consent-container .hs-form-booleancheckbox-display>span,
.legal-consent-container .hs-form-booleancheckbox-display>span p {
    margin-left: 1rem !important;
}

/* Validation */

.hs-form-required {
    color: #EF6B51;
}

.hs-input.invalid.error {
    border-color: #EF6B51;
}

.hs-error-msg {
    color: #EF6B51 !important;
    margin-top: 0.35rem;
}

/* Submit button */

form input[type=submit],
form .button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: all 0.15s linear;
    white-space: normal;
}

/* Captcha */

.grecaptcha-badge {
    margin: 0 auto;
}

button,
.button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: all 0.15s linear;
    white-space: normal;
}

button:disabled,
.button:disabled {
    background-color: #D0D0D0;
    border-color: #D0D0D0;
    color: #E6E6E6;
}

/* No button */

.no-button,
.no-button:hover,
.no-button:focus,
.no-button:active {
    background: none;
    border: none;
    border-radius: 0;
    color: initial;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    transition: none;
}

body {
    line-height: 1.4;
    overflow-wrap: break-word;
}

html[lang^="ja"] body,
html[lang^="zh"] body,
html[lang^="ko"] body {
    line-break: strict;
    overflow-wrap: normal;
    word-break: break-all;
}

/* Paragraphs */

p {
    font-size: 1rem;
    margin: 0 0 1.4rem;
}

/* Anchors */

a {
    cursor: pointer;
}

/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1.4rem;
}

/* Lists */

ul,
ol {
    margin: 0 0 1.4rem;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin: 0;
}

ul.no-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

/* Code blocks */

pre {
    overflow: auto;
}

code {
    vertical-align: bottom;
}

/* Blockquotes */

blockquote {
    border-left: 2px solid;
    margin: 0 0 1.4rem;
    padding-left: 0.7rem;
}

/* Horizontal rules */

hr {
    border: none;
    border-bottom: 1px solid #CCC;
}

/* Image alt text */

img {
    font-size: 0.583rem;
    word-break: normal;
}

/* Form title */

.form-title {
    color: #000000;
}

/* Labels */

form label {
    font-family: inherit;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.25rem;
    display: block;
}

/* Help text */

form legend {
    color: #0000008e;
}

/* Inputs */

form input[type=text],
form input[type=email],
form input[type=password],
form input[type=tel],
form input[type=number],
form input[type=file],
form select,
form textarea {
    background-color: #fff;
    color: #000000;
    border-radius: 0.375rem;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    padding-right: .75rem;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    border-width: 1px;
}

/* Form placeholder text */

::-webkit-input-placeholder {
    color: #fff;
}

::-moz-placeholder {
    color: #fff;
}

:-ms-input-placeholder {
    color: #fff;
}

::placeholder {
    color: #fff;
}

/* Inputs - date picker */

.hs-fieldtype-date .input .hs-dateinput:before {
    color: #fff;
}

.fn-date-picker td.is-selected .pika-button {
    background: #EF6B51;
}

.fn-date-picker td .pika-button:hover {
    background-color: #EF6B51 !important;
}

.fn-date-picker td.is-today .pika-button {
    color: #EF6B51;
}

/* Submit button */

form input[type=submit],
form .hs-button {
    background-color: #EF6B51;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
}

form input[type=submit]:hover,
form input[type=submit]:focus,
form .hs-button:hover,
form .hs-button:focus {
    background-color: #EF6B51;
}
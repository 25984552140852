.card-btn-slide:hover.btn-slide{
    width: 100%;
    outline: 0;
    transition: all 1s;
}

.card-btn-slide.btn-slide{
    position: relative;
    width: fit-content;
    width: -moz-fit-content; /* Add vendor-specific property */
    padding-right: 20px;
    border-radius: 9999px;
    overflow: hidden;
    cursor: pointer;
}

.btn-slide:before {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    border-radius: 9999px;
    height: 3rem;
    position: absolute;
    background: var(--primary-color);
    transition: opacity 1s ease,width 0.7s cubic-bezier(.75,0,.175,1);
    width: 0;
    z-index: -1;
}

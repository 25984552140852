@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./variables.css');
@import url('./assets/css/container.css');
@import url('./assets/css/card.css');
@import url('./assets/css/button.css');
@import url('./assets/css/home.css');
@import url('./assets/css/newform.css');
@import url('./assets/css/underline.css');
@import url('./assets/fonts/Roboto.css');

@font-face {
  font-family: 'Mulish';
  src: url('./assets/fonts/static/Mulish-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.bg-primary-perso{
  background-color: var(--primary-color);
}

.color-primary-perso{
  color: var(--primary-color);
}


body {
  margin: 0;
  font-family: var(--font), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, span {
  font-family: var(--font);
  font-weight: normal;
}


.page-title{
  font-size: 2rem;
  font-weight: 800;
}

@media (min-width: 768px) {
  .page-title{
    font-size: clamp(1rem, -0.875rem + 8.333vw, 5.7rem);
    font-weight: 800;
  }
}

.page-description{
  font-size: clamp(0.25rem, -0.875rem + 8.333vw, 1rem);
}

.layout {
  max-width: 73.5rem;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

@media (min-width: 1024px) {
  .layout {
      max-width:88.5vw
  }
}

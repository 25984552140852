.section {
    transition: all 2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.my-forms-visible {
    animation: fadeIn 0.5s ease-in-out;
}